import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

//image
import logoDark from "../assets/image/logo.png";
import logoEE from "../assets/image/logoEE.png";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const SidebarContent = (props) => {
  const ref = useRef();
  const [collapseRanking, setCollapseRanking] = useState(false);
  const [collapseQuestionario, setCollapseQuestionario] = useState(false);
  const [collapseUploadApp, setCollapseUploadApp] = useState(false);
  const [collapseLogistica, setCollapseLogistica] = useState(false);
  const [docLinks, setDocLinks] = useState(false);
  const [dropTemplates, setDropTemplates] = useState(false);
  const [dropDownload, setDropDownload] = useState(false);
  const [dropCertificate, setDropCertificate] = useState(false);
  const [dropCommunication, setDropCommunication] = useState(false);
  const [dropFunctions, setDropFunctions] = useState(false);
  const location = useLocation();
  const [logoSmall, setLogoSmall] = useState("");
  const [logoLarge, setLogoLarge] = useState("");
  // dropdown products
  const [quotaDistribution, setQuotaDistribution] = useState(false);
  const [scheduling, setScheduling] = useState(false);
  const [appEvent, setAppEvent] = useState(false);
  const [match, setMatch] = useState(false);
  const [rsvp, setRsvp] = useState(false);
  const [ticket, setTicket] = useState(false);
  let navigate = useNavigate();

  function closeDropProducts(product) {
    if (product != "quotas") {
      setQuotaDistribution(false);
    }

    if (product != "scheduling") {
      setScheduling(false);
    }

    if (product != "app") {
      setAppEvent(false);
    }

    if (product != "match") {
      setMatch(false);
    }

    if (product != "rsvp") {
      setRsvp(false);
    }

    if (product != "ticket") {
      setTicket(false);
    }

    closeDrops();
  }

  function closeDrops(e) {
    //states dos drops de sobre o evento
    // if (location?.state?.events) {
    if (e !== "logistica") {
      setCollapseLogistica(false);
    } else setCollapseLogistica(!collapseLogistica);

    if (e !== "certificate") {
      setDropCertificate(false);
    } else setDropCertificate(!dropCertificate);

    if (e !== "downloadDoc") {
      setDropDownload(false);
    } else setDropDownload(!dropDownload);

    if (e !== "functions") {
      setDropFunctions(false);
    } else setDropFunctions(!dropFunctions);

    if (e !== "gamification") {
      setCollapseRanking(false);
    } else setCollapseRanking(!collapseRanking);

    if (e !== "questionario") {
      setCollapseQuestionario(false);
    } else setCollapseQuestionario(!collapseQuestionario);
    if (e !== "uploadapp") {
      setCollapseUploadApp(false);
    } else setCollapseUploadApp(!collapseUploadApp);

    if (e !== "comunication") {
      setDropCommunication(false);
      setDropTemplates(false);
    } else setDropCommunication(!dropCommunication);

    if (e !== "templates") {
      setDropTemplates(false);
    } else {
      setDropCommunication(true);
      setDropTemplates(!dropTemplates);
    }

    //state dos drops do menu primário
    if (e !== "configuration") {
      props.closeMenuConfig();
    } else {
      if (props.state.menu.menuConfig) {
        props.closeMenuConfig();
      } else props.openMenuConfig();
    }

    if (e !== "contract") {
      props.closeMenuContracts();
    } else {
      if (props.state.menu.menuContract) {
        props.closeMenuContracts();
      } else props.openMenuContracts();
    }

    if (e !== "documents") {
      setDocLinks(false);
    } else setDocLinks(!docLinks);

    if (e !== "applications") {
      props.closeMenuApp();
    } else {
      props.openMenuContracts();
      if (props.state.menu.menuApp) {
        props.closeMenuApp();
      } else {
        props.openMenuApp();
      }
    }
  }

  function tToggle() {
    //props.setViewMenu(!props.viewMenu)
    if (props.state.layout_manager.drawer_openend) {
      props.closeNavBar();
    } else {
      props.openNavBar();
    }
  }
  function checkItemMenuExists(menuOption) {
    return (
      props.state.global_user_data.data.foundProducts.filter(
        (item) => item.name === menuOption
      ).length > 0
    );
  }

  function checkProductExists(product) {
    const search = location.state.event.product.filter(
      (e) => e.name === product
    );

    if (search[0]) {
      return true;
    } else return false;
  }

  function checkIcMenu(menuOption) {
    return (
      props.state.global_user_data.data.theme.filter(
        (item) => item.ic === "Inteegra Eventos"
      ).length > 0
    );
  }
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
    setLogoSmall(props?.state?.global_user_data?.data?.theme[0]?.logosmall);
    setLogoLarge(props?.state?.global_user_data?.data?.theme[0]?.logolarge);
  }, [location.pathname]);

  // useEffect(() => {
  //   ref.current.recalculate();
  // });

  //fechar o drop de app quando fechar o drop config
  useEffect(() => {
    if (!props.state.menu.menuConfig) {
      props.closeMenuApp();
    }
  }, [props.state.menu.menuConfig]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight && ref.current) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const [isMobile, setIsMobile] = useState(false);

  const handleNavBar = () => {
    const navbar = document.getElementById("sidebar-menu");
    navbar.classList.toggle("active", !isMobile);
    setIsMobile(!isMobile);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.state.events]);
  return (
    <React.Fragment>
      <div
        style={{
          position: "inline",
          transition: "1s",
          background: "white",
          height: "100%",
        }}
      >
        <div className="h-100">
          <div style={{ maxHeight: "100%" }}>
            <div
              id="sidebar-menu"
              style={
                isMobile
                  ? { minHeight: "100vh", height: "auto", width: "210px" }
                  : {}
              }
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link to="/events" className="logo logo-dark">
                  <span className="logo-lg">
                    {!props.state.layout_manager.drawer_openend ? (
                      <img src={logoSmall || logoEE} alt="" height="20" />
                    ) : (
                      <img src={logoLarge || logoDark} alt="" height="60" />
                    )}
                  </span>
                </Link>
              </div>
              <button
                type="button"
                onClick={() => handleNavBar()}
                className="btn header-item waves-effect btn-hamburger"
              >
                <i
                  className={`${
                    !isMobile ? "fa fa-fw fa-bars" : "mdi mdi-close"
                  }`}
                />
              </button>
              <ul
                className="metismenu list-unstyled active"
                id="side-menu"
                style={isMobile ? { display: "block" } : {}}
              >
                <li className="menu-title">{props.t("Menu")}</li>
                <li>
                  <Link
                    to="/events"
                    className={`waves-effect ${
                      location.pathname === "/events" ? "selected" : null
                    }`}
                    onClick={() => {
                      closeDrops("");
                    }}
                  >
                    <i className="bx bx-calendar"></i>
                    {!props.state.layout_manager.drawer_openend ? null : (
                      <span>Eventos</span>
                    )}
                  </Link>
                  <Link
                    to="/users"
                    className="waves-effect"
                    style={{ display: "none" }}
                  >
                    <i className="mdi mdi-account-circle-outline"></i>
                    {!props.state.layout_manager.drawer_openend ? null : (
                      <span>Usuários</span>
                    )}
                  </Link>
                  {location?.state?.event?.id && (
                    <div
                      // className={classname("dropdown-menu", { show: events })}
                      style={{
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {checkItemMenuExists("AGENDA") &&
                        (checkProductExists("APP Eventos Plus") ||
                          checkProductExists("RSVP") ||
                          checkProductExists("Distribuição de Cotas")) && (
                          <a
                            className={
                              props.state.events === "1" ? "selected" : ""
                            }
                            onClick={() => {
                              props.openEvent("1");
                              closeDropProducts("");

                              navigate("/event/agenda", {
                                state: { ...location?.state },
                              });
                            }}
                          >
                            <i
                              className="mdi mdi-calendar-range"
                              style={{ color: "black" }}
                            />
                            {!props.state.layout_manager
                              .drawer_openend ? null : (
                              <span>Agenda</span>
                            )}
                          </a>
                        )}
                      {checkItemMenuExists("GRUPOS") &&
                        (checkProductExists("APP Eventos Plus") ||
                          checkProductExists("RSVP") ||
                          checkProductExists("Match de Hospedagem") ||
                          checkProductExists(
                            "Gateway de Pagamentos (Adesão)"
                          ) ||
                          checkProductExists("Distribuição de Cotas")) && (
                          <a
                            className={
                              props.state.events === "2" ? "selected" : ""
                            }
                            onClick={() => {
                              props.openEvent("2");
                              closeDropProducts("");

                              navigate("/event/grupos", {
                                state: { ...location?.state },
                              });
                            }}
                          >
                            <i className="bx bxs-group" />
                            {!props.state.layout_manager
                              .drawer_openend ? null : (
                              <span>Grupos</span>
                            )}
                          </a>
                        )}

                      {(checkItemMenuExists("VOO") ||
                        checkItemMenuExists("HOTEL") ||
                        checkItemMenuExists("TRANSFER")) &&
                        (checkProductExists("APP Eventos Plus") ||
                          checkProductExists("RSVP")) && (
                          <a
                            className="waves-effect"
                            onClick={() =>
                              closeDropProducts() > closeDrops("logistica")
                            }
                          >
                            <i className="mdi mdi-taxi" />
                            {!props.state.layout_manager
                              .drawer_openend ? null : (
                              <span className="option-drop-menu">
                                Logística
                              </span>
                            )}
                            {collapseLogistica ? (
                              <i className="bx bx-chevron-up" />
                            ) : (
                              <i className="bx bx-chevron-down" />
                            )}
                          </a>
                        )}

                      {collapseLogistica && (
                        <div style={{ marginLeft: "10px" }}>
                          {checkItemMenuExists("VOO") &&
                            (checkProductExists("APP Eventos Plus") ||
                              checkProductExists("RSVP")) && (
                              <a
                                className={
                                  props.state.events === "3"
                                    ? "selected waves-effect"
                                    : "waves-effect"
                                }
                                onClick={() => {
                                  props.openEvent("3");

                                  navigate("/event/logistica/voo", {
                                    state: { ...location?.state },
                                  });
                                }}
                              >
                                <i className="mdi mdi-airplane" />
                                {!props.state.layout_manager
                                  .drawer_openend ? null : (
                                  <span>Voo</span>
                                )}
                              </a>
                            )}
                          {checkItemMenuExists("TRANSFER") &&
                            (checkProductExists("APP Eventos Plus") ||
                              checkProductExists("RSVP")) && (
                              <a
                                className={
                                  props.state.events === "4"
                                    ? "selected waves-effect"
                                    : "waves-effect"
                                }
                                onClick={() => {
                                  props.openEvent("4");

                                  navigate("/event/logistica/transfer", {
                                    state: { ...location?.state },
                                  });
                                }}
                              >
                                <i className="bx bxs-car" />
                                {!props.state.layout_manager
                                  .drawer_openend ? null : (
                                  <span>Transfer</span>
                                )}
                              </a>
                            )}
                          {checkItemMenuExists("HOTEL") &&
                            (checkProductExists("APP Eventos Plus") ||
                              checkProductExists("RSVP")) && (
                              <a
                                className={
                                  props.state.events === "5"
                                    ? "selected waves-effect"
                                    : "waves-effect"
                                }
                                onClick={() => {
                                  props.openEvent("5");

                                  navigate("/event/logistica/hotel", {
                                    state: { ...location?.state },
                                  });
                                }}
                              >
                                <i className="bx bxs-hotel" />
                                {!props.state.layout_manager
                                  .drawer_openend ? null : (
                                  <span>Hotel</span>
                                )}
                              </a>
                            )}
                        </div>
                      )}
                      {checkItemMenuExists("PARTICIPANTES") && (
                        <a
                          className={
                            props.state.events === "6" ? "selected" : ""
                          }
                          onClick={() => {
                            props.openEvent("6");
                            closeDropProducts("");

                            navigate("/event/participantes", {
                              state: { ...location?.state },
                            });
                          }}
                        >
                          <i className="bx bx-user" />
                          {!props.state.layout_manager.drawer_openend ? null : (
                            <span>Participantes</span>
                          )}
                        </a>
                      )}

                      {/* Section agendamento */}
                      {checkProductExists("Agendamento") && (
                        <section>
                          <li
                            className="menu-subtitle product-drop"
                            onClick={() => {
                              setScheduling(!scheduling);
                              closeDropProducts("scheduling");
                            }}
                          >
                            {props.t("Agendamentos")}
                            {scheduling ? (
                              <i className="bx bx-chevron-up icon-product-drop" />
                            ) : (
                              <i className="bx bx-chevron-down icon-product-drop" />
                            )}
                          </li>

                          {scheduling && (
                            <>
                              {checkItemMenuExists("ESTOQUE") && (
                                <a
                                  className={
                                    props.state.events === "37"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("37");
                                    closeDrops("");
                                    navigate("/event/estoque", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                >
                                  <i className="bx bxl-dropbox" />
                                  {!props.state?.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Estoque</span>
                                  )}
                                </a>
                              )}

                              {checkItemMenuExists(
                                "RELATORIO DE AGENDAMENTOS"
                              ) && (
                                <a
                                  className={
                                    props.state.events === "38"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("38");
                                    closeDrops("");
                                    navigate("/event/relatorio-agendamento", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                >
                                  <i className="bx bxs-report" />
                                  {!props.state?.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Relatório de Agendamento</span>
                                  )}
                                </a>
                              )}
                            </>
                          )}
                        </section>
                      )}

                      {/* Section App Eventos */}
                      {checkProductExists("APP Eventos Plus") && (
                        <section>
                          <li
                            className="menu-subtitle product-drop"
                            onClick={() => {
                              setAppEvent(!appEvent);
                              closeDropProducts("app");
                            }}
                          >
                            {props.t("APP Eventos")}
                            {appEvent ? (
                              <i className="bx bx-chevron-up icon-product-drop" />
                            ) : (
                              <i className="bx bx-chevron-down icon-product-drop" />
                            )}
                          </li>

                          {appEvent && (
                            <>
                              {(checkItemMenuExists("CERTIFICADOS") ||
                                checkItemMenuExists(
                                  "RELATORIO_PARTICIPANTES_CERTIFICADOS"
                                )) && (
                                <a
                                  className="waves-effect"
                                  onClick={() => {
                                    closeDrops("certificate");
                                  }}
                                >
                                  <i className="mdi mdi-certificate-outline" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Certificado</span>
                                  )}
                                  {dropCertificate ? (
                                    <i className="bx bx-chevron-up" />
                                  ) : (
                                    <i className="bx bx-chevron-down" />
                                  )}
                                </a>
                              )}
                              {dropCertificate && (
                                <div style={{ marginLeft: "10px" }}>
                                  {checkItemMenuExists("CERTIFICADOS") && (
                                    <a
                                      className={
                                        props.state.events === "8"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("8");
                                        navigate("/event/certificados", {
                                          state: { ...location?.state },
                                        });
                                      }}
                                    >
                                      <i className="mdi mdi-certificate" />
                                      {!props.state?.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Certificados</span>
                                      )}
                                    </a>
                                  )}

                                  {checkItemMenuExists(
                                    "RELATORIO_PARTICIPANTES_CERTIFICADOS"
                                  ) && (
                                    <a
                                      className={
                                        props.state.events === "9"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("9");
                                        navigate(
                                          "/event/certificados/relatorio",
                                          {
                                            state: { ...location?.state },
                                          }
                                        );
                                      }}
                                    >
                                      <i className="bx bxs-report" />
                                      {!props.state?.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Relatório por Participante</span>
                                      )}
                                    </a>
                                  )}
                                </div>
                              )}
                              {checkItemMenuExists("DASHBOARD") && (
                                <a
                                  className={
                                    props.state.events === "10"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("10");
                                    closeDrops("");
                                    navigate("/event/dashboard", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                >
                                  <i className="bx bxs-pie-chart-alt-2" />
                                  {!props.state?.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Dashboard</span>
                                  )}
                                </a>
                              )}
                              {(checkItemMenuExists("RELATORIO_DOCUMENTOS") ||
                                checkItemMenuExists(
                                  "RELATORIO_PARTICIPANTES"
                                ) ||
                                checkItemMenuExists("RELATORIO_DOWNLOADS")) && (
                                <a
                                  className="waves-effect"
                                  onClick={() => {
                                    closeDrops("downloadDoc");
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="bx bxs-download" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Download de Documentos
                                    </span>
                                  )}
                                  {dropDownload ? (
                                    <i className="bx bx-chevron-up" />
                                  ) : (
                                    <i className="bx bx-chevron-down" />
                                  )}
                                </a>
                              )}

                              {dropDownload && (
                                <div style={{ marginLeft: "10px" }}>
                                  {checkItemMenuExists(
                                    "RELATORIO_DOCUMENTOS"
                                  ) && (
                                    <a
                                      className={
                                        props.state.events === "11"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("11");

                                        navigate("/event/download/documentos", {
                                          state: { ...location?.state },
                                        });
                                      }}
                                    >
                                      <i className="mdi mdi-file-document-edit-outline" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Documentos</span>
                                      )}
                                    </a>
                                  )}
                                  {checkItemMenuExists(
                                    "RELATORIO_PARTICIPANTES"
                                  ) && (
                                    <a
                                      className={
                                        props.state.events === "12"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("12");

                                        navigate(
                                          "/event/download/relatorio/participantes",
                                          {
                                            state: { ...location?.state },
                                          }
                                        );
                                      }}
                                    >
                                      <i className="mdi mdi-file-document-multiple-outline" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Relatório de participantes</span>
                                      )}
                                    </a>
                                  )}
                                  {checkItemMenuExists(
                                    "RELATORIO_DOWNLOADS"
                                  ) && (
                                    <a
                                      className={
                                        props.state.events === "13"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("13");

                                        navigate(
                                          "/event/download/relatorio/downloads",
                                          {
                                            state: { ...location?.state },
                                          }
                                        );
                                      }}
                                    >
                                      <i className="mdi mdi-file-download-outline" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Relatório de downloads</span>
                                      )}
                                    </a>
                                  )}
                                </div>
                              )}

                              {checkItemMenuExists("FEED") && (
                                <a
                                  className={
                                    props.state.events === "14"
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    props.openEvent("14");
                                    closeDrops("");

                                    navigate("/event/feed", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                >
                                  <i className="mdi mdi-post" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Feed</span>
                                  )}
                                </a>
                              )}
                              {(checkItemMenuExists("CRIAR_FUNCAO_JS") ||
                                checkItemMenuExists(
                                  "RELATORIO_PARTICIPANTES_FUNCAO_JS"
                                )) && (
                                <a
                                  className="waves-effect"
                                  onClick={() => {
                                    closeDrops("functions");
                                  }}
                                >
                                  <i className="mdi mdi-language-javascript" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Funções JS</span>
                                  )}
                                  {dropFunctions ? (
                                    <i className="bx bx-chevron-up" />
                                  ) : (
                                    <i className="bx bx-chevron-down" />
                                  )}
                                </a>
                              )}
                              {dropFunctions && (
                                <div style={{ marginLeft: "10px" }}>
                                  {checkItemMenuExists("CRIAR_FUNCAO_JS") && (
                                    <a
                                      className={
                                        props.state.events === "15"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("15");

                                        navigate("/event/functions", {
                                          state: { ...location?.state },
                                        });
                                      }}
                                    >
                                      <i className="bx bx-code-block" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Criar Função</span>
                                      )}
                                    </a>
                                  )}
                                  {checkItemMenuExists(
                                    "RELATORIO_PARTICIPANTES_FUNCAO_JS"
                                  ) && (
                                    <a
                                      className={
                                        props.state.events === "16"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("16");

                                        navigate("/event/functions/relatorio", {
                                          state: { ...location?.state },
                                        });
                                      }}
                                    >
                                      <i className="bx bxs-report" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Relatório por Participantes</span>
                                      )}
                                    </a>
                                  )}
                                </div>
                              )}
                              {(checkItemMenuExists("RANKING_GERAL") ||
                                checkItemMenuExists("RANKING_CONFIG")) && (
                                <a
                                  className="waves-effect"
                                  onClick={() => {
                                    closeDrops("gamification");
                                  }}
                                >
                                  <i className="mdi mdi-podium" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Gamification</span>
                                  )}
                                  {collapseRanking ? (
                                    <i className="bx bx-chevron-up" />
                                  ) : (
                                    <i className="bx bx-chevron-down" />
                                  )}
                                </a>
                              )}
                              {collapseRanking && (
                                <div style={{ marginLeft: "10px" }}>
                                  {checkItemMenuExists("RANKING_GERAL") && (
                                    <a
                                      className={
                                        props.state.events === "17"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("17");

                                        navigate("/event/ranking", {
                                          state: { ...location?.state },
                                        });
                                      }}
                                    >
                                      <i className="mdi mdi-view-list" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Ranking Geral</span>
                                      )}
                                      {/* {isMobile && <span>Ranking Geral</span>} */}
                                    </a>
                                  )}
                                  {checkItemMenuExists("RANKING_CONFIG") && (
                                    <a
                                      className={
                                        props.state.events === "18"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("18");

                                        navigate("/event/ranking/settings", {
                                          state: { ...location?.state },
                                        });
                                      }}
                                    >
                                      <i className="mdi mdi-cog-outline" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Configurações</span>
                                      )}
                                    </a>
                                  )}
                                </div>
                              )}
                              {/* {checkItemMenuExists("GRUPOS") && (
                            <a
                              className={
                                props.state.events === "19" ? "selected" : ""
                              }
                              onClick={() => {
                                props.openEvent("19");
                                closeDrops("");
                                navigate("/event/grupos", {
                                  state: { ...location?.state },
                                });
                              }}
                            >
                              <i className="bx bxs-group" />
                              {!props.state.layout_manager
                                .drawer_openend ? null : (
                                <span>Grupos</span>
                              )}
                            </a>
                          )} */}

                              {checkItemMenuExists("MENU_APP") && (
                                <a
                                  className={
                                    props.state.events === "20"
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    props.openEvent("20");
                                    closeDrops("");

                                    navigate("/event/menu", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                >
                                  <i className="bx bx-menu-alt-left" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Menu do App</span>
                                  )}
                                </a>
                              )}
                              {checkItemMenuExists("NOTIFICACOES_PUSH") && (
                                <a
                                  className={
                                    props.state.events === "21"
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    props.openEvent("21");
                                    closeDrops("");

                                    navigate("/event/notifications", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                >
                                  <i className="bx bx-notification" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Notificações Push</span>
                                  )}
                                </a>
                              )}

                              {checkItemMenuExists(
                                "QUESTIONARIO_RELATORIO_RESPOSTAS"
                              ) && (
                                <a
                                  className="waves-effect"
                                  onClick={() => {
                                    closeDrops("questionario");
                                  }}
                                >
                                  <i className="mdi mdi-clipboard-edit-outline" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Questionários</span>
                                  )}
                                  {collapseQuestionario ? (
                                    <i className="bx bx-chevron-up" />
                                  ) : (
                                    <i className="bx bx-chevron-down" />
                                  )}
                                </a>
                              )}
                              {collapseQuestionario && (
                                <div style={{ marginLeft: "10px" }}>
                                  {checkItemMenuExists(
                                    "QUESTIONARIO_RELATORIO_RESPOSTAS"
                                  ) && (
                                    <a
                                      className={
                                        props.state.events === "22"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("22");

                                        navigate(
                                          "/event/questionario/relatorio",
                                          {
                                            state: { ...location?.state },
                                          }
                                        );
                                      }}
                                    >
                                      <i className="mdi mdi-view-list" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Relatórios de Respostas</span>
                                      )}
                                    </a>
                                  )}
                                </div>
                              )}

                              {checkItemMenuExists("SUPER_TELA") && (
                                <a
                                  className={
                                    props.state.events === "23"
                                      ? "selected"
                                      : ""
                                  }
                                  onClick={() => {
                                    props.openEvent("23");
                                    closeDrops("");

                                    navigate("/event/superTela", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                >
                                  <i className="bx bx-notification" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Super Tela</span>
                                  )}
                                </a>
                              )}

                              {checkItemMenuExists(
                                "UPLOADAPP_RELATORIO_PARTICIPANTES"
                              ) && (
                                <a
                                  className="waves-effect"
                                  onClick={() => {
                                    closeDrops("uploadapp");
                                  }}
                                >
                                  <i className="mdi mdi-cloud-upload" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span>Uploads</span>
                                  )}
                                  {collapseUploadApp ? (
                                    <i className="bx bx-chevron-up" />
                                  ) : (
                                    <i className="bx bx-chevron-down" />
                                  )}
                                </a>
                              )}
                              {collapseUploadApp && (
                                <div style={{ marginLeft: "10px" }}>
                                  {checkItemMenuExists(
                                    "UPLOADAPP_RELATORIO_PARTICIPANTES"
                                  ) && (
                                    <a
                                      className={
                                        props.state.events === "24"
                                          ? "selected waves-effect"
                                          : "waves-effect"
                                      }
                                      onClick={() => {
                                        props.openEvent("24");

                                        navigate("/event/upload/relatorio", {
                                          state: { ...location?.state },
                                        });
                                      }}
                                    >
                                      <i className="mdi mdi-view-list" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Relatório por Participante</span>
                                      )}
                                    </a>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </section>
                      )}

                      {checkProductExists("Distribuição de Cotas") && (
                        <>
                          <section>
                            <li
                              className="menu-subtitle product-drop"
                              onClick={() => {
                                setQuotaDistribution(!quotaDistribution);
                                closeDropProducts("quotas");
                              }}
                            >
                              {props.t("Distribuição de Cotas")}
                              {quotaDistribution ? (
                                <i className="bx bx-chevron-up icon-product-drop" />
                              ) : (
                                <i className="bx bx-chevron-down icon-product-drop" />
                              )}
                            </li>

                            {quotaDistribution && (
                              <>
                                {checkItemMenuExists("SORTEIO_COTAS") && (
                                  <a
                                    className={
                                      props.state.events === "7"
                                        ? "selected waves-effect"
                                        : "waves-effect"
                                    }
                                    onClick={() => {
                                      props.openEvent("7");
                                      closeDrops("");
                                      navigate("/event/sorteio", {
                                        state: { ...location?.state },
                                      });
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <i className="mdi mdi-dice-multiple" />
                                    {!props.state.layout_manager
                                      .drawer_openend ? null : (
                                      <span className="option-drop-menu">
                                        Sorteios
                                      </span>
                                    )}
                                  </a>
                                )}
                              </>
                            )}
                          </section>
                        </>
                      )}

                      {/* Section MATCH */}
                      {checkItemMenuExists("RELATORIO_HOSPEDAGEM") &&
                        checkProductExists("Match de Hospedagem") && (
                          <section>
                            <li
                              className="menu-subtitle product-drop"
                              onClick={() => {
                                setMatch(!match);
                                closeDropProducts("match");
                              }}
                            >
                              {props.t("Match de Hospedagem")}
                              {match ? (
                                <i className="bx bx-chevron-up icon-product-drop" />
                              ) : (
                                <i className="bx bx-chevron-down icon-product-drop" />
                              )}
                            </li>

                            {match && (
                              <>
                                {checkItemMenuExists(
                                  "RELATORIO_HOSPEDAGEM"
                                ) && (
                                  <a
                                    className={
                                      props.state.events === "25"
                                        ? "selected"
                                        : ""
                                    }
                                    onClick={() => {
                                      props.openEvent("25");
                                      closeDrops("");
                                      navigate("/event/match/relatorio", {
                                        state: { ...location?.state },
                                      });
                                    }}
                                    style={{ display: "flex" }}
                                  >
                                    <i className="mdi mdi-view-list" />
                                    {!props.state.layout_manager
                                      .drawer_openend ? null : (
                                      <span className="option-drop-menu">
                                        Relatório de Hospedagem
                                      </span>
                                    )}
                                  </a>
                                )}
                              </>
                            )}
                          </section>
                        )}
                      {/* Section RSVP */}
                      {checkProductExists("RSVP") && (
                        <section>
                          <li
                            className="menu-subtitle product-drop"
                            onClick={() => {
                              setRsvp(!rsvp);
                              closeDropProducts("rsvp");
                            }}
                          >
                            {props.t("RSVP")}
                            {rsvp ? (
                              <i className="bx bx-chevron-up icon-product-drop" />
                            ) : (
                              <i className="bx bx-chevron-down icon-product-drop" />
                            )}
                          </li>

                          {rsvp && (
                            <>
                              {(checkItemMenuExists("FORMULARIO_DINAMICO") ||
                                checkItemMenuExists("NOTIFICACOES_PUSH") ||
                                checkItemMenuExists("EMAIL") ||
                                checkItemMenuExists("PAGINA_WEB")) && (
                                <a
                                  className="waves-effect"
                                  onClick={() => {
                                    closeDrops("comunication");
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="mdi mdi-message-text-outline" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Comunicação
                                    </span>
                                  )}
                                  {dropCommunication ? (
                                    <i className="bx bx-chevron-up" />
                                  ) : (
                                    <i className="bx bx-chevron-down" />
                                  )}
                                </a>
                              )}

                              {dropCommunication && (
                                <div style={{ marginLeft: "10px" }}>
                                  {checkItemMenuExists(
                                    "FORMULARIO_DINAMICO"
                                  ) && (
                                    <a
                                      className={
                                        props.state.events === "26"
                                          ? "selected"
                                          : ""
                                      }
                                      onClick={() => {
                                        closeDrops("comunication");
                                        setDropCommunication(true);
                                        props.openEvent("26");
                                        navigate("/event/rsvp/formulario", {
                                          state: { ...location?.state },
                                        });
                                      }}
                                    >
                                      <i className="bx bx-badge-check" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Formulário Dinâmico</span>
                                      )}
                                    </a>
                                  )}

                                  {(checkItemMenuExists("EMAIL") ||
                                    checkItemMenuExists("PAGINA_WEB")) && (
                                    <a
                                      className="waves-effect"
                                      onClick={() => {
                                        closeDrops("templates");
                                      }}
                                    >
                                      <i className="fa-solid fa-code" />
                                      {!props.state.layout_manager
                                        .drawer_openend ? null : (
                                        <span>Templates</span>
                                      )}
                                      {dropTemplates ? (
                                        <i className="bx bx-chevron-up" />
                                      ) : (
                                        <i className="bx bx-chevron-down" />
                                      )}
                                    </a>
                                  )}
                                  {dropTemplates && (
                                    <div style={{ marginLeft: "10px" }}>
                                      {checkItemMenuExists("EMAIL") && (
                                        <a
                                          className={
                                            props.state.events === "27"
                                              ? "selected waves-effect"
                                              : "waves-effect"
                                          }
                                          onClick={() => {
                                            props.openEvent("27");
                                            navigate("/event/templates/email", {
                                              state: { ...location?.state },
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-email" />
                                          {!props.state.layout_manager
                                            .drawer_openend ? null : (
                                            <span>Email</span>
                                          )}
                                        </a>
                                      )}
                                      {checkItemMenuExists("PAGINA_WEB") && (
                                        <a
                                          className={
                                            props.state.events === "28"
                                              ? "selected waves-effect"
                                              : "waves-effect"
                                          }
                                          onClick={() => {
                                            props.openEvent("28");
                                            navigate("/event/templates/web", {
                                              state: { ...location?.state },
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-web" />
                                          {!props.state.layout_manager
                                            .drawer_openend ? null : (
                                            <span>Página WEB</span>
                                          )}
                                        </a>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                        </section>
                      )}

                      {/* Section GATEWAY DE PAGAMENTO */}
                      {checkProductExists("Gateway de Pagamentos (Adesão)") && (
                        <section>
                          <li
                            className="menu-subtitle product-drop"
                            onClick={() => {
                              setTicket(!ticket);
                              closeDropProducts("ticket");
                            }}
                          >
                            {props.t("TICKETEIRA")}
                            {ticket ? (
                              <i className="bx bx-chevron-up icon-product-drop" />
                            ) : (
                              <i className="bx bx-chevron-down icon-product-drop" />
                            )}
                          </li>

                          {ticket && (
                            <>
                              {checkItemMenuExists("CONFIGURACOES_ADESAO") && (
                                <a
                                  className={
                                    props.state.events === "29"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("29");
                                    closeDrops("");
                                    navigate("", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="dripicons-gear" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Configurações
                                    </span>
                                  )}
                                </a>
                              )}
                              {checkItemMenuExists("CUPONS") && (
                                <a
                                  className={
                                    props.state.events === "30"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("30");
                                    closeDrops("");
                                    navigate("/event/cupons", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="fas fa-ticket-alt" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Cupons
                                    </span>
                                  )}
                                </a>
                              )}
                              {checkItemMenuExists("DASHBOARD_VENDAS") && (
                                <a
                                  className={
                                    props.state.events === "31"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("31");
                                    closeDrops("");
                                    navigate("/event/dashboard-tupperware", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="bx bxs-pie-chart-alt-2" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Dashboard de Vendas
                                    </span>
                                  )}
                                </a>
                              )}
                              {checkItemMenuExists("INGRESSOS") && (
                                <a
                                  className={
                                    props.state.events === "32"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("32");
                                    closeDrops("");
                                    navigate("/event/tickets", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="mdi mdi-ticket-outline" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Ingressos
                                    </span>
                                  )}
                                </a>
                              )}
                              {checkItemMenuExists("SPLITS") && (
                                <a
                                  className={
                                    props.state.events === "33"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("33");
                                    closeDrops("");
                                    navigate("/event/splits", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="mdi mdi-call-split" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Splits
                                    </span>
                                  )}
                                </a>
                              )}

                              {checkItemMenuExists("VENDAS_ANALITICAS") && (
                                <a
                                  className={
                                    props.state.events === "36"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("36");
                                    closeDrops("");
                                    navigate("/event/vendas-analiticas", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="bx bx-money" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Vendas Analíticas
                                    </span>
                                  )}
                                </a>
                              )}

                              {checkItemMenuExists("VENDAS_CONSOLIDADAS") && (
                                <a
                                  className={
                                    props.state.events === "35"
                                      ? "selected waves-effect"
                                      : "waves-effect"
                                  }
                                  onClick={() => {
                                    props.openEvent("35");
                                    closeDrops("");
                                    navigate("/event/vendas-consolidadas", {
                                      state: { ...location?.state },
                                    });
                                  }}
                                  style={{ display: "flex" }}
                                >
                                  <i className="bx bx-money" />
                                  {!props.state.layout_manager
                                    .drawer_openend ? null : (
                                    <span className="option-drop-menu">
                                      Vendas Consolidadas
                                    </span>
                                  )}
                                </a>
                              )}
                            </>
                          )}
                        </section>
                      )}
                    </div>
                  )}

                  {/* <Link to="/historic" className="waves-effect">
                    <i className="bx bx-history"></i>
                    {!props.state.layout_manager.drawer_openend ? null : (
                      <span>Histórico</span>
                    )}
                  </Link> */}
                  <a
                    className="waves-effect"
                    onClick={() => {
                      closeDrops("configuration");
                    }}
                  >
                    <i className="dripicons-gear" />
                    {!props.state.layout_manager.drawer_openend ? null : (
                      <span className="option-drop-menu">Configurações</span>
                    )}
                    {props.state.menu.menuConfig ? (
                      <i className="bx bx-chevron-up" />
                    ) : (
                      <i className="bx bx-chevron-down" />
                    )}
                  </a>
                  {props.state.menu.menuConfig && (
                    <div style={{ marginLeft: "10px" }}>
                      {checkItemMenuExists("INTEEGRA_CREATOR") && (
                        <Link
                          to="/inteegra-creator"
                          className={`waves-effect ${
                            location.pathname === "/inteegra-creator"
                              ? "selected"
                              : null
                          }`}
                          onClick={() => props.closeMenuApp()}
                        >
                          <i
                            className="mdi mdi-language-html5"
                            ref={(el) => {
                              if (el) {
                                el.style.setProperty(
                                  "color",
                                  "#495057",
                                  "important"
                                );
                              }
                            }}
                          />
                          {!props.state.layout_manager.drawer_openend ? null : (
                            <span>Inteegra Creator</span>
                          )}
                        </Link>
                      )}
                      {checkItemMenuExists("INTEEGRA_DRIVE") && (
                        <Link
                          to="/inteegra-drive"
                          className={`waves-effect ${
                            location.pathname === "/inteegra-drive"
                              ? "selected"
                              : null
                          }`}
                          onClick={() => props.closeMenuApp()}
                        >
                          <i
                            className="bx bx-image"
                            ref={(el) => {
                              if (el) {
                                el.style.setProperty(
                                  "color",
                                  "#495057",
                                  "important"
                                );
                              }
                            }}
                          />
                          {!props.state.layout_manager.drawer_openend ? null : (
                            <span>Inteegra Drive</span>
                          )}
                        </Link>
                      )}
                      {checkItemMenuExists("TEMPLATES") && (
                        <Link
                          to="/templates"
                          className={`waves-effect ${
                            (location.pathname === "/templates" ||
                              location.pathname === "/html-editor") &&
                            props.state.events === "34"
                              ? "selected"
                              : null
                          }`}
                          onClick={() => {
                            props.openEvent("34");
                            props.closeMenuApp();
                          }}
                        >
                          <i
                            className="fa-solid fa-code"
                            ref={(el) => {
                              if (el) {
                                el.style.setProperty(
                                  "color",
                                  "#495057",
                                  "important"
                                );
                              }
                            }}
                          />
                          {!props.state.layout_manager.drawer_openend ? null : (
                            <span>Templates</span>
                          )}
                        </Link>
                      )}

                      {checkItemMenuExists("TIPOS_DE_EVENTO") && (
                        <Link
                          to="/type-event"
                          className={`waves-effect ${
                            location.pathname === "/type-event"
                              ? "selected"
                              : null
                          }`}
                          onClick={() => props.closeMenuApp()}
                        >
                          <i className="bx bx-notepad" />
                          {!props.state.layout_manager.drawer_openend ? null : (
                            <span>Tipos de Evento</span>
                          )}
                        </Link>
                      )}

                      {checkItemMenuExists("USUARIOS") && (
                        <Link
                          to="/users"
                          className={`waves-effect ${
                            location.pathname === "/users" ? "selected" : null
                          }`}
                          onClick={() => props.closeMenuApp()}
                        >
                          {/* <Link to="/users" className="waves-effect selected"> */}

                          <i className="mdi mdi-account-circle-outline"></i>
                          {!props.state.layout_manager.drawer_openend ? null : (
                            <span>Usuários</span>
                          )}
                        </Link>
                      )}
                    </div>
                  )}
                  {checkIcMenu() && (
                    <a
                      className="waves-effect"
                      onClick={() => {
                        closeDrops("contract");
                      }}
                    >
                      <i className="dripicons-gear" />
                      {!props.state.layout_manager.drawer_openend ? null : (
                        <span className="option-drop-menu">Contratos</span>
                      )}
                      {props.state.menu.menuContract ? (
                        <i className="bx bx-chevron-up" />
                      ) : (
                        <i className="bx bx-chevron-down" />
                      )}
                    </a>
                  )}
                  {props.state.menu.menuContract && (
                    <div style={{ marginLeft: "10px" }}>
                      {checkIcMenu() && (
                        // checkProductExists("APP Eventos Plus") && (
                        <a
                          className="waves-effect"
                          onClick={() => closeDrops("applications")}
                        >
                          <i
                            className="mdi mdi-apps"
                            ref={(el) => {
                              if (el) {
                                el.style.setProperty(
                                  "color",
                                  "#495057",
                                  "important"
                                );
                              }
                            }}
                          />
                          {!props.state.layout_manager.drawer_openend ? null : (
                            <span className="option-drop-menu">
                              Aplicativos
                            </span>
                          )}
                          {props.state.menu.menuApp ? (
                            <i className="bx bx-chevron-up" />
                          ) : (
                            <i className="bx bx-chevron-down" />
                          )}
                        </a>
                      )}
                      {props.state.menu.menuApp && (
                        <div style={{ marginLeft: "10px" }}>
                          {checkIcMenu() && (
                            // checkItemMenuExists("APP_EVENTOS_PLUS") && (
                            // checkProductExists("APP Eventos Plus") && (
                            <Link
                              to="/mobile-apps"
                              className={`waves-effect ${
                                location.pathname === "/mobile-apps"
                                  ? "selected"
                                  : null
                              }`}
                            >
                              <i
                                className="mdi mdi-cellphone"
                                ref={(el) => {
                                  if (el) {
                                    el.style.setProperty(
                                      "color",
                                      "#495057",
                                      "important"
                                    );
                                  }
                                }}
                              />
                              {!props.state.layout_manager
                                .drawer_openend ? null : (
                                <span>Versões do App</span>
                              )}
                            </Link>
                          )}
                          {checkIcMenu() && (
                            // checkItemMenuExists("APP_EVENTOS_PLUS") && (
                            <Link
                              to="/dictionary"
                              className={`waves-effect ${
                                location.pathname === "/dictionary"
                                  ? "selected"
                                  : null
                              }`}
                            >
                              <i
                                className="bx bx-book-open"
                                ref={(el) => {
                                  if (el) {
                                    el.style.setProperty(
                                      "color",
                                      "#495057",
                                      "important"
                                    );
                                  }
                                }}
                              />
                              {!props.state.layout_manager
                                .drawer_openend ? null : (
                                <span>Dicionário</span>
                              )}
                            </Link>
                          )}
                        </div>
                      )}

                      {checkIcMenu() && (
                        <Link
                          to="/contracts"
                          onClick={() => props.closeMenuApp()}
                          className={`waves-effect ${
                            location.pathname === "/contracts"
                              ? "selected"
                              : null
                          }`}
                        >
                          <i
                            className="bx bx-file"
                            ref={(el) => {
                              if (el) {
                                el.style.setProperty(
                                  "color",
                                  "#495057",
                                  "important"
                                );
                              }
                            }}
                          />
                          {!props.state.layout_manager.drawer_openend ? null : (
                            <span>Contratos</span>
                          )}
                        </Link>
                      )}
                    </div>
                  )}

                  {checkIcMenu() && (
                    <a
                      className="waves-effect"
                      onClick={() => {
                        closeDrops("documents");
                      }}
                    >
                      <i className="mdi mdi-file-cabinet" />
                      {!props.state.layout_manager.drawer_openend ? null : (
                        <span className="option-drop-menu">Documentação</span>
                      )}
                      {docLinks ? (
                        <i className="bx bx-chevron-up" />
                      ) : (
                        <i className="bx bx-chevron-down" />
                      )}
                    </a>
                  )}
                  {docLinks && (
                    <div style={{ marginLeft: "10px" }}>
                      <a
                        href="https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2023/e8925f6a-872c-4905-8ce0-7535874fb470.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2126689903&Signature=fkOWw557iZUVTZpoAzOpeIHnS4s%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="mdi mdi-text-box-outline" />
                        {!props.state.layout_manager.drawer_openend ? null : (
                          <span>Especificação APP Eventos</span>
                        )}
                      </a>

                      <a
                        href="https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2023/96d3d342-2f0f-4792-b4fe-fd40bc90cfbd.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2126689935&Signature=igpQkXnrMH5vFVa8lXzpwzwNW4c%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="mdi mdi-text-box-outline" />
                        {!props.state.layout_manager.drawer_openend ? null : (
                          <span>Especificação Match de Hospedagem</span>
                        )}
                      </a>

                      <a
                        href="https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2023/f5fbcf45-2195-46cf-bf5c-e88d3e1b7473.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&Expires=2102420313&Signature=3w4x1xtR8ddNGeb7pO24McmxTx4%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="mdi mdi-text-box-outline" />
                        {!props.state.layout_manager.drawer_openend ? null : (
                          <span>Especificação RSVP</span>
                        )}
                      </a>
                      <a
                        href="https://drive.google.com/drive/folders/1V7Wy0Gkm4Ah9-F1uvi15_KFAqE81JVKC"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="mdi mdi-text-box-outline" />
                        {!props.state.layout_manager.drawer_openend ? null : (
                          <span>Guias e Apresentações</span>
                        )}
                      </a>
                      <a
                        href="https://s3.amazonaws.com/bucket.app.eventos/gallery/8029badb-d64c-4af8-9f52-59b60e346767/2022/3d5faeb3-9492-460d-b939-cce147a44413.pdf?AWSAccessKeyId=AKIAJ2EWX2HWZM4NBALA&amp;Expires=2092320411&amp;Signature=5zStJ1VTixW4IQ%2B2DsPM1b74Eck%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="mdi mdi-text-box-outline" />
                        {!props.state.layout_manager.drawer_openend ? null : (
                          <span>Manual (DOC)</span>
                        )}
                      </a>
                      <a
                        href="https://s3.us-east-1.amazonaws.com/bucket.aws.360/App_Instrucoes/index.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="mdi mdi-text-box-outline" />
                        {!props.state.layout_manager.drawer_openend ? null : (
                          <span>Manual (WEB)</span>
                        )}
                      </a>
                    </div>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

const mapStateToProps = function (state) {
  return { state: state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    openEvent: (data) => {
      return dispatch({ type: "OPEN_EVENT_MENU", data });
    },
    openNavBar: (data) => {
      return dispatch({ type: "OPEN_GLOBAL_LAYOUT_MENU_NAV_WEB", data });
    },
    closeNavBar: (data) => {
      return dispatch({ type: "CLOSE_GLOBAL_LAYOUT_MENU_NAV_WEB", data });
    },
    openMenuConfig: () => {
      return dispatch({
        type: "open_menuConfig",
      });
    },
    closeMenuConfig: () => {
      return dispatch({
        type: "close_menuConfig",
      });
    },
    openMenuApp: () => {
      return dispatch({
        type: "open_menuApp",
      });
    },
    closeMenuApp: () => {
      return dispatch({
        type: "close_menuApp",
      });
    },
    openMenuContracts: () => {
      return dispatch({
        type: "OPEN_MENU_CONTRACTS",
      });
    },
    closeMenuContracts: () => {
      return dispatch({
        type: "CLOSE_MENU_CONTRACTS",
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SidebarContent));
