import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Button,
  Collapse,
  Table,
} from "reactstrap";
// componentes
import SectionComponent from "../../Apps/Personalization/SectionComponent";
import SearchFilters from "../../../components/SearchFilters";
import Pagination from "../../../components/Paginationv3";
import WaitExport from "../../../components/WaitExport";
// utils e service
import {
  deleteScheduling,
  getScheduling,
} from "../../../utils/services/scheduling";
import useDebounce from "../../../utils/hooks/useDebounce";
// redux
import { useLocation } from "react-router-dom";
// exportar
import { createXlsx } from "./createXlsx";
import { ModalNewScheduling } from "./ModalNewScheduling";
import { connect } from "react-redux";
import { getParticipantsLogistic } from "../../../utils/services/participants";
import { useQueries } from "react-query";
import SweetAlert from "react-bootstrap-sweetalert";

function SchedulingReport(props) {
  const location = useLocation();
  const eventId = location.state.idEvent;
  const token = props.state.global_user_data.data.token;
  const participants = useRef(null);

  // const eventId = "f2beb642-45c3-4ada-abb6-ca3c6688e369";
  // export in progress
  const [exportInProgress, setExportInProgress] = useState(false);
  const [finishExport, setFinishExport] = useState(false);
  // listagem
  const [list, setList] = useState([]);
  // pesquisa
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);
  // collapse state
  const [isOpen, setIsOpen] = useState(false);
  const [modalNewScheduling, setModalNewScheduling] = useState(false);
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);
  // paginacao
  const [currentIndex, setCurrentIndex] = useState(1);
  const [countData, setCountData] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // filtro
  const [activeFilter, setActiveFilter] = useState("participantName");
  const columnsFilter = [
    { name: "Participante", value: "participantName", type: "text" },
    { name: "Atividade", value: "atividade", type: "text" },
    { name: "Data", value: "dataHoraInicio", type: "date" },
  ];

  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  function setActiveFilterHandle(columnActive) {
    setActiveFilter(columnActive);
    setSearch("");
  }

  function formatDateTime(data) {
    const datePart = data.split("T");
    return `${formatDate(datePart[0])} ${datePart[1].substring(0, 5)}`;
  }

  function formatDate(date) {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  function getList() {
    getScheduling({
      eventId: eventId,
      offset: currentIndex,
      limit: itemsPerPage,
      search: debouncedSearch,
      columnSearch: activeFilter,
    })
      .then((res) => {
        setCountData(res.total);
        setList(res.data);
      })
      .catch((error) => console.log("erro", error));
  }

  const data = useQueries([
    {
      queryKey: ["getAllParticipants" + location.state.idEvent, 1],
      queryFn: async () => {
        const partForm = await getParticipantsLogistic({
          jwt: props.state.global_user_data.data.token,
          idEvent: eventId,
          offset: 0,
          limit: 0,
          search: "",
        });

        participants.current = partForm?.data?.data;
      },
    },
  ]);

  useEffect(() => {
    getList();
  }, [debouncedSearch, itemsPerPage, currentIndex]);

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setDeleteConfirmAlert(true);
  };

  const confirmDelete = () => {
    deleteScheduling(selectedId, token)
      .then(() => {
        setSuccessAlert(true);
        setDeleteConfirmAlert(false);
        getList();
      })
      .catch((error) => {
        console.error("Erro ao excluir agendamento", error);
        setDeleteConfirmAlert(false);
      });
  };

  return (
    <>
      {exportInProgress && (
        <WaitExport
          isOpen={exportInProgress}
          name="Agendamentos"
          preposicao="dos"
          finishExport={finishExport}
          setFinishExport={setFinishExport}
          setExportInProgress={setExportInProgress}
        />
      )}

      {modalNewScheduling && (
        <ModalNewScheduling
          isOpen={modalNewScheduling}
          toggle={() => setModalNewScheduling(false)}
          idEvent={eventId}
          participants={participants?.current}
          token={token}
          refetch={getList}
          list={list}
        />
      )}

      <SweetAlert
        warning
        showCancel
        show={deleteConfirmAlert}
        title="Deseja deletar esse agendamento?"
        confirmBtnText="Sim"
        cancelBtnText="Não"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="secondary"
        onConfirm={confirmDelete}
        onCancel={() => setDeleteConfirmAlert(false)}
      >
        Você não poderá reverter essa ação.
      </SweetAlert>

      <SweetAlert
        success
        show={successAlert}
        title="Deletado!"
        confirmBtnText="OK"
        onConfirm={() => {
          setSuccessAlert(false);
          setDeleteConfirmAlert(false);
        }}
      >
        Agendamento deletado!
      </SweetAlert>

      <Card>
        <CardBody>
          <CardTitle>Relatório de Agendamentos</CardTitle>

          <div>
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "50px",
              }}
            >
              <SectionComponent
                sectionName="Filtros"
                setIsOpen={setCollapseIsOpen}
                isOpen={collapseIsOpen}
              />
            </Row>
            <Collapse isOpen={collapseIsOpen}>
              <SearchFilters
                columns={columnsFilter}
                activeFilter={activeFilter}
                setActiveFilterHandle={setActiveFilterHandle}
              />
            </Collapse>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  value={search}
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt" />
              </div>
            </form>

            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <Row className="mobile-btns-top">
                <form
                  className="app-search"
                  style={{ padding: "0px" }}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="position-relative">
                    <input
                      type="text"
                      value={search}
                      className="form-control"
                      placeholder="Pesquisar..."
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="bx bx-search-alt" />
                  </div>
                </form>
              </Row>

              {countData > 0 && (
                <>
                  <SectionComponent
                    sectionName="Opções"
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                  />

                  <Collapse isOpen={isOpen}>
                    <Row
                      className="mobile-btns-top"
                      onClick={() => setModalNewScheduling(true)}
                    >
                      <div>Exportar Relatório</div>
                    </Row>
                  </Collapse>
                </>
              )}
            </div>

            <div className="header-btns-container">
              {countData > 0 && (
                <Button
                  color="success"
                  className="btn-rounded waves-effect waves-light events-button-add-lg"
                  onClick={() =>
                    createXlsx({
                      eventId: eventId,
                      limit: 10000,
                      search: debouncedSearch,
                      columnSearch: activeFilter,
                      countData,
                      setFinishExport,
                    }) > setExportInProgress(true)
                  }
                >
                  Exportar Relatório
                </Button>
              )}

              <Button
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add-lg"
                onClick={() => setModalNewScheduling(true)}
              >
                Criar Agendamento
              </Button>
            </div>
          </div>

          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>ID do Participante</th>
                  <th>Nome do Participante</th>
                  <th>Sala</th>
                  <th>Atividade</th>
                  <th>Data e Hora Início</th>
                  <th>Data e Hora Fim</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {list.length > 0 &&
                  list.map((item, index) => (
                    <tr key={index}>
                      <td>{item.participantId}</td>
                      <td>{item.participantName}</td>
                      <td>{item.sala}</td>
                      <td>{item.atividade}</td>
                      <td>{formatDateTime(item.dataHoraInicio)}</td>
                      <td>{formatDateTime(item.dataHoraFim)}</td>
                      <td style={{ textAlign: "center" }}>
                        <i
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          className="mdi mdi-trash-can"
                          onClick={() => handleDeleteClick(item.id)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>

      <Pagination
        data={list}
        search={search}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        countData={countData}
        maxItemPerPage={itemsPerPage}
        setMaxItemPerPage={setItemsPerPage}
        maxPageButtonsToShow={3}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  state: state,
});

export default connect(mapStateToProps)(SchedulingReport);
