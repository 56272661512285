export const excelColumnsPtBr = {
  Sala: "room",
  Atividade: "task",
  "Data e Hora Inicio": "initialDate",
  "Data e Hora Fim": "endDate",
  "Quantidade Máxima": "maxQuantity",
};

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    room: row.room ? row.room : undefined,
    task: row.task ? row.task : undefined,
    initialDate: row.initialDate ? row.initialDate : undefined,
    endDate: row.endDate ? row.endDate : undefined,
    maxQuantity: row.maxQuantity ? row.maxQuantity : undefined,
  };

  return formatedRow;
}

export function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) =>
      row.room === undefined ||
      row.task === undefined ||
      row.initialDate === undefined ||
      row.endDate === undefined ||
      row.maxQuantity === undefined
  );

  return rowsWithError;
}
