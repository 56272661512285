import { Modal, ModalBody, ModalHeader, Row, Col, Button } from "reactstrap";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";
import { useEffect, useState } from "react";
import { getAllStockActivities } from "../../../utils/services/schedulingAndStock";
import axios from "axios";
import { urlBase } from "../../../utils/services/config";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";

export const ModalNewScheduling = (props) => {
  const { isOpen, toggle, idEvent, participants, token, refetch, list } = props;

  const [form, setForm] = useState({
    participant: "",
    eventId: "",
    activityId: "",
    activityName: "",
    sala: "",
    dataHoraInicio: "",
    dataHoraFim: "",
  });

  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [filteredParticipants, setFilteredParticipants] =
    useState(participants);
  const [participantSearchQuery, setParticipantSearchQuery] = useState("");
  const [activitySearchQuery, setActivitySearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [participantActivities, setParticipantActivities] = useState([]);

  const [successCreateAlert, setSuccessCreateAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [fullActivityAlert, setFullActivityAlert] = useState(false);

  const getActivities = async () => {
    setIsFetching(true);
    let allActivities = [];
    let offset = 1;
    const limit = 100;
    let hasMoreActivities = true;

    try {
      while (hasMoreActivities) {
        const res = await getAllStockActivities({
          eventId: idEvent,
          token: token,
          offset: offset,
          limit: limit,
        });

        const fetchedActivities = res.data;

        allActivities = [...allActivities, ...fetchedActivities];

        if (fetchedActivities.length < limit) {
          hasMoreActivities = false;
        } else {
          offset++;
        }
      }

      setActivities(allActivities);
      setFilteredActivities(allActivities);
    } catch (error) {
      console.log("Erro ao buscar atividades", error);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getActivities();
      setFilteredParticipants(participants);
    }
  }, [isOpen, participants]);

  const handleParticipantSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setParticipantSearchQuery(query);
    const filteredParticipants = participants.filter((participant) =>
      participant.nome.toLowerCase().includes(query)
    );
    setFilteredParticipants(filteredParticipants);
  };

  const handleActivitySearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setActivitySearchQuery(query);
    const filteredActivities = activities.filter((activity) =>
      activity.atividade.toLowerCase().includes(query)
    );
    setFilteredActivities(filteredActivities);
  };

  const filterActivitiesByParticipant = (selectedParticipantId) => {
    const participantActivities = list
      .filter((item) => item.participantId === selectedParticipantId)
      .map((item) => ({
        activityId: item.activityId,
        startTime: item.dataHoraInicio,
        endTime: item.dataHoraFim,
      }));

    setParticipantActivities(participantActivities);

    const updatedFilteredActivities = activities.filter(
      (activity) =>
        !participantActivities.some((p) => p.activityId === activity.id)
    );

    setFilteredActivities(updatedFilteredActivities);
  };

  const handleActivityChange = (e) => {
    const selectedActivity = activities.find(
      (activity) => activity.id === e.target.value
    );
    if (selectedActivity) {
      setForm((prevForm) => ({
        ...prevForm,
        eventId: selectedActivity.eventId,
        activityId: selectedActivity.id,
        activityName: selectedActivity.atividade,
        sala: selectedActivity.sala,
        dataHoraInicio: selectedActivity.dataHoraInicio,
        dataHoraFim: selectedActivity.dataHoraFim,
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        eventId: "",
        activityId: "",
        activityName: "",
        sala: "",
        dataHoraInicio: "",
        dataHoraFim: "",
      }));
    }
  };

  const handleParticipantChange = (e) => {
    const selectedParticipant = participants.find(
      (participant) => participant.id === e.target.value
    );
    if (selectedParticipant) {
      setForm((prevForm) => ({
        ...prevForm,
        participant: selectedParticipant.id,
      }));
      filterActivitiesByParticipant(selectedParticipant.id);
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        participant: "",
      }));
      setFilteredActivities(activities);
    }
  };

  const checkForTimeConflicts = (startTime, endTime) => {
    return participantActivities.some((activity) => {
      const activityStart = moment(activity.startTime);
      const activityEnd = moment(activity.endTime);
      const selectedStart = moment(startTime);
      const selectedEnd = moment(endTime);

      return (
        selectedStart.isBetween(activityStart, activityEnd, null, "[)") ||
        selectedEnd.isBetween(activityStart, activityEnd, null, "(]") ||
        activityStart.isBetween(selectedStart, selectedEnd, null, "[)")
      );
    });
  };

  const checkIfActivityIsFull = (activityId) => {
    const activity = activities.find((a) => a.id === activityId);
    if (!activity) return false;
    return activity.quantidadeUtilizada >= activity.quantidadeMax;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (checkForTimeConflicts(form.dataHoraInicio, form.dataHoraFim)) {
      setErrorAlert(true);
      setLoading(false);
      return;
    }

    if (checkIfActivityIsFull(form.activityId)) {
      setFullActivityAlert(true);
      setLoading(false);
      return;
    }

    const payload = {
      eventId: form.eventId,
      participantId: form.participant,
      atividade: form.activityName,
      sala: form.sala,
      dataHoraInicio: form.dataHoraInicio,
      dataHoraFim: form.dataHoraFim,
    };

    try {
      const response = await axios.post(`${urlBase}/agendamentos`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Agendamento criado com sucesso:", response.data);
      refetch();

      setSuccessCreateAlert(true);
      setForm({
        participant: "",
        eventId: "",
        activityId: "",
        activityName: "",
        sala: "",
        dataHoraInicio: "",
        dataHoraFim: "",
      });
    } catch (error) {
      console.log("Erro ao criar agendamento", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} centered autoFocus>
      <ModalHeader toggle={toggle}>Novo Agendamento</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12} style={{ marginBottom: 10 }}>
              <label className="form-label text-sm">Participante: *</label>
              <input
                className="form-control"
                type="text"
                value={participantSearchQuery}
                onChange={handleParticipantSearchChange}
                placeholder="Buscar participantes..."
              />
              <select
                className="form-control"
                value={form.participant || ""}
                onChange={handleParticipantChange}
                style={{ marginTop: 10 }}
              >
                <option value="">{"Selecione um participante"}</option>
                {filteredParticipants.map((participant) => (
                  <option key={participant.id} value={participant.id}>
                    {participant.nome}
                  </option>
                ))}
              </select>
            </Col>

            <Col lg={12} style={{ marginBottom: 10 }}>
              <div>
                <label className="form-label">Atividade: *</label>
                <input
                  className="form-control"
                  type="text"
                  value={activitySearchQuery}
                  onChange={handleActivitySearchChange}
                  placeholder="Buscar atividades..."
                />
                <select
                  className="form-control"
                  value={form.activityId || ""}
                  onChange={handleActivityChange}
                  style={{ marginTop: 10 }}
                >
                  <option value="">
                    {isFetching ? "Buscando..." : "Selecione uma atividade"}
                  </option>
                  {filteredActivities.map((activity) => (
                    <option key={activity.id} value={activity.id}>
                      {activity.atividade}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              style={{ width: 80, marginRight: 10 }}
              onClick={toggle}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={loading} title={"Salvar"} type="submit" />
          </Row>
        </form>

        {successCreateAlert && (
          <SweetAlert
            success
            title="Sucesso!"
            onConfirm={() => {
              setSuccessCreateAlert(false);
              toggle();
            }}
            confirmBtnBsStyle="success"
          >
            Agendamento criado com sucesso!
          </SweetAlert>
        )}

        {errorAlert && (
          <SweetAlert
            danger
            title="Erro!"
            onConfirm={() => setErrorAlert(false)}
          >
            O participante já está em outra atividade neste horário.
          </SweetAlert>
        )}

        {fullActivityAlert && (
          <SweetAlert
            danger
            title="Erro!"
            onConfirm={() => setFullActivityAlert(false)}
          >
            Atividade não possui estoque.
          </SweetAlert>
        )}
      </ModalBody>
    </Modal>
  );
};
