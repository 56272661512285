import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Card,
  CardText,
  Form,
  CardBody,
  Modal,
  ModalHeader,
} from "reactstrap";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
import readXlsxFile from "read-excel-file";
import ModeloUpload from "../../../assets/statics/modelo_agenda_cotas.xlsx";
import { formatBytes } from "../../../utils/helpers/files/formatBytes";
import {
  allExcelColumns,
  excelColumnsPtBr,
  findRowsWithError,
} from "./ImportValidation";
import ModalExcelRows from "../Voo/ModalExcelRows";
import moment from "moment";
import { importStock } from "../../../utils/services/schedulingAndStock";

export default function ModalImportStock(props) {
  const { isOpen, toggle, idEvent, token, refetch } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [success_dlg, setSuccessDlg] = useState(false);
  const [imgError, setImgError] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [totalSended, setTotalSended] = useState(0);
  const [btnSend, setBtnSend] = useState(false);
  const [btnCancel, setBtnCancel] = useState(false);
  const importActivated = useRef(false);

  const [modalErrors, setModalErrors] = useState(false);
  const [errors, setErrors] = useState([]);

  let timerID = null;

  useEffect(() => {
    setSelectedFiles([]);
    setImgError("");
  }, [isOpen]);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setSelectedFiles(files);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(true);
    clearTimeout(timerID);
  }

  function cancelImport() {
    importActivated.current = false;
    setSelectedFiles([]);
    setTotalRows(0);
    setTotalSended(0);
    setBtnSend(false);
    setBtnCancel(false);
  }

  async function uploadService() {
    importActivated.current = true;
    clearTimeout(timerID);
    setBtnSend(false);
    setBtnCancel(true);

    const schema = {
      Sala: {
        prop: "room",
        type: String,
      },
      Atividade: {
        prop: "task",
        type: String,
      },
      "Data e Hora Inicio": {
        prop: "initialDate",
        type: Date,
        parse(value) {
          return moment(value, "DD/MM/YYYY, HH:mm")
            .subtract(3, "hours")
            .toDate();
        },
      },
      "Data e Hora Fim": {
        prop: "endDate",
        type: Date,
        parse(value) {
          return moment(value, "DD/MM/YYYY, HH:mm")
            .subtract(3, "hours")
            .toDate();
        },
      },
      "Quantidade Máxima": {
        prop: "maxQuantity",
        type: Number,
      },
    };

    let count = 1;

    const readXlsx = async () => {
      try {
        const { rows } = await readXlsxFile(selectedFiles[0], { schema });
        const validRows = rows.map((row, i) => allExcelColumns(row, i));
        const rowsWithError = findRowsWithError(validRows);

        setTotalRows(validRows.length);

        if (rowsWithError.length > 0 || validRows.length === 0) {
          setErrors(rowsWithError);
          setModalErrors(true);
        } else {
          for (let i = 0; i < validRows.length; i++) {
            await new Promise((resolve) => {
              setTimeout(async () => {
                await importStockService(validRows[i], i, validRows.length);
                resolve();
              }, 500 * i);
            });
          }
        }
      } catch (error) {
        console.error("Error reading file:", error);
        setImgError("Erro ao ler o arquivo. Verifique o formato.");
      }
    };

    readXlsx();

    async function importStockService(row, index, totalRows) {
      try {
        const result = await importStock({
          eventId: idEvent,
          sala: row.room,
          atividade: row.task,
          dataHoraInicio: row.initialDate,
          dataHoraFim: row.endDate,
          quantidadeMax: row.maxQuantity,
          quantidadeUtilizada: 0,
          jwt: token,
        });

        if (result.message === "created") {
          setTotalSended(count++);
        }

        if (totalRows === index + 1) {
          setBtnCancel(false);
          setSuccessDlg(true);
          refetch();
        }
      } catch (error) {
        console.error("Error importing stock:", error);
      }
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} autoFocus={true} centered={true}>
      {success_dlg && (
        <SweetAlert
          success
          title="Upload"
          onConfirm={() => {
            toggle();
            setSuccessDlg(false);
          }}
        >
          O arquivo foi enviado com sucesso!
        </SweetAlert>
      )}

      {modalErrors && (
        <ModalExcelRows
          toggle={() => setModalErrors(false)}
          rows={errors}
          columnNames={excelColumnsPtBr}
        />
      )}

      <ModalHeader toggle={toggle}>Importação Estoque</ModalHeader>
      <div className="m-1 p-0">
        <Card className="p-1">
          <CardBody className="m-1 p-2">
            <CardText className="mb-2 p-10">
              O arquivo a ser importado deve seguir este{" "}
              <a href={ModeloUpload} download="modelo_agenda_cotas.xlsx">
                Modelo
              </a>
              , não modifique seu cabeçalho.
            </CardText>

            <Form>
              {selectedFiles.length === 0 && (
                <Dropzone
                  onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick mt-1"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="mb-4">
                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                          <h4>Arraste o arquivo ou clique aqui e selecione.</h4>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}

              <div className="dropzone-previews mt-0" id="file-previews">
                {selectedFiles.map((f, i) => (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-1">
                      <Row className="align-items-center">
                        <Col xs="11">
                          <strong>{`Nome: ${f.name} - Tamanho: ${f.formattedSize}`}</strong>
                        </Col>
                        <Col xs="1">
                          <span
                            style={{
                              fontSize: "16px",
                              color: "red",
                              fontWeight: "700",
                            }}
                            className="mdi mdi-close"
                            onClick={() => setSelectedFiles([])}
                          />
                        </Col>
                      </Row>
                      {importActivated.current && (
                        <Row className="align-items-center">
                          <Col>
                            <strong>{`Total enviado: ${totalSended}/${totalRows}`}</strong>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </Card>
                ))}
              </div>
            </Form>

            <div className="text-center mt-3">
              <p style={{ color: "red" }}>{imgError}</p>
              <button
                type="button"
                disabled={!btnSend}
                className="btn btn-primary waves-effect waves-light"
                onClick={uploadService}
              >
                Enviar Programação
              </button>
              <button
                type="button"
                className="btn btn-danger mx-1"
                disabled={!btnCancel}
                onClick={cancelImport}
              >
                Cancelar
              </button>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}
